import React, { useMemo } from 'react';
import { CSSProp } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { EmbedVideoWrapper, ThumbnailWrapper } from './EmbeddedVideo.atoms';

// Molecules

// Assets

// Utils
import { TContentfulEmbedVideo } from 'src/utils/types';

export type EmbedWrapper = { fluid?: boolean, height?: CSSProp<'height'> };
type TContentfulEmbedVideoComponent = EmbedWrapper & TContentfulEmbedVideo;

const ContentfulEmbedVideoThumbnail: React.FC<TContentfulEmbedVideoComponent> = (props) => {
	const {
		fluid,
		height,
		styled,
		title,
		videoId,
	} = props;
	const { allFile: { edges } } = useStaticQuery(graphql`
		query thumbnail {
			allFile(filter: {name: {regex: "/^embed-thumbnail-.+/"  }}){
				edges {
					node {
						name
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED , placeholder: BLURRED, formats: [AUTO, AVIF])
						}
					}
				}
			}
		}
	`);

	const thumbnail = useMemo(() => edges.find(({ node }: { node: { name: string } }) => node.name === `embed-thumbnail-${videoId}`)?.node, [edges, videoId]);
	return (
		<Flex
			className="embedded-video-player"
			justifyContent="center"
			h="100%"
			styled={styled}
		>
			<Flex
				w="fit-content"
				h="fit-content"
				direction="column"
				transform="translateZ(0)"
				overflow="hidden"
			>
				<EmbedVideoWrapper fluid={fluid} height={height}>
					<ThumbnailWrapper
						isPlaying={false}
					>
						<GatsbyImage
							className="thumbnail"
							image={thumbnail?.childImageSharp?.gatsbyImageData}
							alt={title}
						/>
					</ThumbnailWrapper>
				</EmbedVideoWrapper>
			</Flex>
		</Flex>
	);
};

export default ContentfulEmbedVideoThumbnail;

ContentfulEmbedVideoThumbnail.defaultProps = {
	fluid: false,
	height: undefined,
};
