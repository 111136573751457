import styled, { css } from 'styled-components';

export const Button = styled.button<{ selected?: boolean, rotated?: boolean }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	font-weight: ${props => props.theme.fontWeight.medium};
	color: ${props => props.selected ? props.theme.color.primary : props.theme.color.textColor};
	text-align: center;
	min-width: 32px;
	border-radius: 4px;
	border: 1px solid transparent;
	${props => props.rotated && css`transform: scale(-1);`}

	&:hover {
		color: ${props => props.theme.color.primaryHover};
		border: 1px solid ${props => props.theme.color.lightGrey};
	}

	&:disabled {
		pointer-events: none;
		color: ${props => props.theme.color.lightGrey};
		svg {
			fill: ${props => props.theme.color.lightGrey};
		}
	}
`;
