import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Button } from './Pagination.atoms';

// Assets
import {
	LuChevronFirst, LuChevronLast, LuChevronLeft, LuChevronRight,
} from 'react-icons/lu';

type TPagination = {
	currentPage: number
	numPages: number
}

const Pagination: React.FC<TPagination> = (props) => {
	const { currentPage, numPages } = props;

	const { search, pathname } = useLocation();

	const handlePageChange = (value: string) => () => {
		const urlSearchParams = new URLSearchParams(search);
		let newPage = currentPage;
		switch (value) {
			case 'next': {
				newPage += 1;
				break;
			}
			case 'back': {
				newPage -= 1;
				break;
			}
			default: {
				newPage = +value;
			}
		}
		if (newPage && newPage !== 1) {
			urlSearchParams.set('page', newPage.toString());
		} else {
			urlSearchParams.delete('page');
		}
		const newSearch = urlSearchParams.toString();
		navigate(newSearch ? `?${newSearch}` : pathname);
	};

	const renderNavigation = () => {
		const pagination = [];
		let start = currentPage - 2 > 0 ? currentPage - 2 : 1;
		if (start > numPages - 4) {
			start = numPages - 4 > 0 ? numPages - 4 : 1;
		}
		const end = start + 4 < numPages ? start + 4 : numPages;
		if (numPages > 5) {
			if (numPages > 10) {
				pagination.push(<Button key="goToFirst" onClick={handlePageChange('1')} disabled={currentPage === 1}><LuChevronFirst /></Button>);
			}
			pagination.push(<Button key="goBack" onClick={handlePageChange('back')} disabled={currentPage === 1}><LuChevronLeft /></Button>);
		}

		for (let i = start; i <= end; i += 1) {
			pagination.push(<Button selected={i === currentPage} key={i} onClick={handlePageChange(i.toString())}>{i}</Button>);
		}

		if (numPages > 5) {
			pagination.push(<Button key="goToNext" onClick={handlePageChange('next')} disabled={currentPage === numPages}><LuChevronRight /></Button>);
			if (numPages > 10) {
				pagination.push(<Button key="goToLast" onClick={handlePageChange(numPages.toString())} disabled={currentPage === numPages}><LuChevronLast /></Button>);
			}
		}
		return pagination;
	};

	return numPages > 1 ? (
		<Flex
			paddingTop="s40"
			gap="6px"
			justifyContent="center"
		>
			{renderNavigation()}
		</Flex>
	) : null;
};

export default Pagination;
