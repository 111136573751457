import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import styled from 'styled-components';

export const FocusSectionWrapper = styled(Section)`
    background-color: #1a4e6b;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
    padding: 48px 32px;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 500;

`;
export const BannerWrapper = styled(Wrapper)`
 
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;


    img {
        width: 100% !important;
    }
`;

export const FocusSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    width: 180px;
    gap: 16px;

    svg {
        height: 48px;
        path {
            stroke: white !important;
        }
    }

    ${Text} {
        line-height: normal;
        font-size: 16px;
    }
`;
