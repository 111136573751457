import styled, { css } from 'styled-components';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

type TDot = {
  checked: boolean;
  disabled?: boolean;
};

export const Dot = styled.div<TDot>`
  position: relative;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.lines};
  background-color: ${props => props.theme.color.white};

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.color.primary};
    opacity: ${(props: TDot) => (props.checked ? 1 : 0)};
  }
`;

export const RadioLabelWrapper = styled(Flex)<TDot>`
  display: flex;
  width: auto;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  ${props => props.disabled && css`
    cursor: not-allowed;
    color: ${props.theme.textColor.secondary};
  `}

  &:hover {
    border-color: ${props => props.theme.color.primary};
  }
`;
