import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { ReactZoomPanPinchProps, TransformWrapper } from 'react-zoom-pan-pinch';

const TransformWrapperNoPan = (props: ReactZoomPanPinchProps) => {
	const [isInitialView, setIsInitialView] = useState(true);
	const ref = useRef<HTMLDivElement>(null);
	const [lastTouch, setLastTouch] = useState<number | null>(null);

	useEffect(() => {
		const div = ref.current;

		div?.addEventListener('wheel', onWheel, { capture: true });
		div?.addEventListener('dblclick', onDoubleClick, { capture: true });
		div?.addEventListener('touchstart', onTouchStart, { capture: true });

		return () => {
			div?.removeEventListener('wheel', onWheel, { capture: true });
			div?.removeEventListener('dblclick', onDoubleClick, { capture: true });
			div?.removeEventListener('touchstart', onTouchStart, { capture: true });
		};
	}, []);

	const onWheel = (event: WheelEvent) => {
		if (!isInitialView) {
			return;
		}
		if (event.deltaY > 0) {
			event.stopPropagation();
		} else if (event.deltaY < 0) {
			setIsInitialView(false);
		}
	};

	const onDoubleClick = () => {
		if (isInitialView) {
			setIsInitialView(false);
		}
	};

	const onTouchStart = (event: TouchEvent) => {
		const isDoubleTap = lastTouch && +new Date() - lastTouch < 200;
		if (isDoubleTap && event.touches.length === 1) {
			onDoubleClick();
		} else {
			setLastTouch(+new Date());
		}
	};

	const { children, ...restProps } = props;

	return (
		<div ref={ref}>
			<TransformWrapper
				panning={{ disabled: isInitialView }}
				onPinchingStop={ref => setIsInitialView(ref.state.scale <= 1)}
				{...restProps}
			>
				{children}
			</TransformWrapper>
		</div>
	);
};

export default TransformWrapperNoPan;
