import React from 'react';
import { TProductLabel } from 'src/utils/types';
import { Label, LabelsWrapper } from './Labels.atoms';

export type TLabels = {
    items: TProductLabel[]
}

const Labels: React.FC<TLabels> = (props) => {
	const {
		items,
	} = props;

	return (
		<LabelsWrapper>
			{items.map((item, index) => (
				<Label
					style={{
						backgroundImage: `url(${item.label?.image?.urls?.original})`,
					}}
				>
					{item.label?.name}
				</Label>
			))}
		</LabelsWrapper>
	);
};

export default Labels;
