import styled from 'styled-components';

export const LabelsWrapper = styled.ul`
    position: relative;
    box-sizing: border-box;
    padding: 0;
`;

export const Label = styled.li`
    display: inline;
    margin-bottom: 8px;
    margin-right: 16px;
    line-height: 1.5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    padding: 0 0 0 24px;
`;
