import React from 'react';
import ReactModal from 'react-modal';

// Atoms
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { LuX } from 'react-icons/lu';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

// Assets

const Modal: React.FC<ReactModal.Props & { closeModal: () => void }> = (props) => {
	const { closeModal, children, ...rest } = props;

	return (
		<ReactModal
			portalClassName="react-modal-portal"
			className="modal"
			overlayClassName="overlay"
			appElement={typeof document !== 'undefined' ? document.body : undefined}
			{...rest}
		>
			<Flex
				position="relative"
				paddingY="s64"
				paddingX="s64"
				background="white"
				borderRadius="4px"
				standardDesktop={[{ padding: ['l32', 'r32'] }]}
				largeTablet={[
					{ padding: ['b20', 't56', 'l20', 'r20'] },
					{ marginBottom: 's32' },
				]}
			>
				<Button
					position="absolute"
					top="40px"
					right="40px"
					variant="noStyle"
					onClick={closeModal}
					desktop={[{ top: '20px', right: '20px' }]}
				>
					<Icon as={LuX} w="24px" h="auto" />
				</Button>
				{children}
			</Flex>
		</ReactModal>
	);
};

export default Modal;
