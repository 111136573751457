import React from 'react';
import { DiscountedPrice, RegularPrice, RegularPriceCrossed } from './Price.atoms';

export type TPrice = {
	price: number;
	discountedPrice?: number | null;
}

const Price: React.FC<TPrice> = (props) => {
	const {
		price,
		discountedPrice = null,
	} = props;

	if (discountedPrice && discountedPrice < price) {
		return (
			<>
				<RegularPriceCrossed>{`$${price.toFixed(2)}`}</RegularPriceCrossed>
				<DiscountedPrice>{`$${discountedPrice.toFixed(2)}`}</DiscountedPrice>
			</>
		);
	}

	return <RegularPrice>{`$${price.toFixed(2)}`}</RegularPrice>;
};

Price.defaultProps = {
	discountedPrice: null,
};

export default Price;
