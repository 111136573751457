import React from 'react';

// Atoms
import { TFlex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Dot, RadioLabelWrapper } from './RadioLabel.atoms';

type TRadioLabel = {
	name: string;
	value: string;
	checked?: boolean;
	onClick?: () => void;
	children: React.ReactNode;
	disabled?: boolean;
} & TFlex;

const RadioLabel: React.FC<TRadioLabel> = (props) => {
	const {
		checked = false, onClick, children, ...rest
	} = props;
	return (
		<RadioLabelWrapper checked={checked} onClick={onClick} {...rest}>
			<Dot checked={checked} />
			<div>{children}</div>
		</RadioLabelWrapper>
	);
};

export default RadioLabel;

RadioLabel.defaultProps = {
	checked: false,
	onClick: undefined,
	disabled: false,
};
