import styled from 'styled-components';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const SecondaryText = styled(Text)`
    color: ${props => props.theme.color.textColor2};
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 5px;
    background-color: ${props => props.theme.color.bgLight};
    border-radius: 5px;

    & > div {
        height: 5px;
        border-radius: 5px;
        background-color: ${props => props.theme.color.primary};
    }
`;

export const Percentage = styled(Text)`
    background-color: ${props => props.theme.color.bgLight};
    border-radius: 4px;
    padding: 2px 16px;
    width: 90px;
    text-align: center;
    margin-left: 16px;
`;

export const PercentageWrapper = styled(Wrapper)`
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid ${props => props.theme.color.lightBeige};
`;

export const ReviewWrapper = styled(Flex)`
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid ${props => props.theme.color.lightBeige};
    flex-direction: column;
    &:last-child {
        border-bottom: none;
    }
`;

export const Reply = styled(Flex)`
    padding-left: 16px;
    border-left: 2px solid ${props => props.theme.color.primary};
    flex-direction: row;
    align-items: flex-start;
    margin-top: 32px;
    * {
        font-size: 14px;
    }
`;

export const ErrorText = styled(Text)`
    line-height: 1.5;
    bottom: 0px;
    color: #DE3131;
    font-size: 0.75rem;
    line-height: normal;
    display: block;
    padding: 4px 13px;
`;

export const UploadArea = styled(Flex)`
    border: 1px dashed #DDD;
    border-radius: 4px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 32px;
`;

export const UploadImage = styled.div`
    height: 100px;
    overflow: hidden;
    margin-right: 8px;
    position: relative;
    
    div {
        display: none;
    }

    button {
        display: none;
    }

    &:hover {
        div {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100px;
            background-color: rgba(0,0,0,0.3);
            width: 100%;
        }

        button {
            position: absolute;
            display: block;
            top: 8px;
            right: 8px;
            color: white;
            cursor: pointer;
            text-shadow: 0 0 5px black;
        }
    }
`;

export const ReviewImage = styled.img`
    height: 192px;
    margin-right: 8px;
    margin-bottom: 8px;
`;
