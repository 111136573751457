import React, {
	useCallback,
	useEffect, useMemo, useRef, useState,
} from 'react';
import { css, useTheme } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
// Molecules
import ProductView from 'src/components/Molecules/ProductView';
import ContentfulEmbedVideo from 'src/contentful/ContentfulEmbedVideo';

// Sections
import Section from 'fatcat-ui-library/components/Molecules/Section';

// Types
import { TBlogPost, TProduct } from 'src/utils/types';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';

// Icons
import descriptionIcon from '../../../assets/images/icons/about/description.svg';
import suggestedIcon from '../../../assets/images/icons/about/suggested.svg';
import ingredientsIcon from '../../../assets/images/icons/about/ingredients.svg';
import warningsIcon from '../../../assets/images/icons/about/warnings.svg';
import overviewIcon from '../../../assets/images/icons/about/overview.svg';

import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import Accordion from 'src/components/Molecules/Accordion/Accordion';
import Reviews from 'src/components/Molecules/Reviews';
import { useLocation } from '@reach/router';
import ContentfulProductsSlider from 'src/contentful/ContentfulProductSlider';
import BlogCard from 'src/components/Molecules/Cards/BlogCard';
import ContentfulLink from 'src/contentful/ContentfulLink';
import { LuDownload } from 'react-icons/lu';
import { BannerWrapper, FocusSectionItem, FocusSectionWrapper } from './Product.atoms';

import NoColors from 'src/assets/images/svg-inline/focus/no-artificial-colours.svg';
import Compliant from 'src/assets/images/svg-inline/focus/tick.svg';
import ThirdParty from 'src/assets/images/svg-inline/focus/microscope.svg';
import FormulatedByDoctor from 'src/assets/images/svg-inline/focus/pharmacy.svg';
import NoFillers from 'src/assets/images/svg-inline/focus/no-fillers.svg';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

import BannerImage2 from 'src/assets/images/banners/spring-savings-2024/spring-savings-narrow-2.jpg';
import BannerImage3 from 'src/assets/images/banners/spring-savings-2024/spring-savings-narrow-3.jpg';
import BannerImage4 from 'src/assets/images/banners/spring-savings-2024/spring-savings-narrow-4.jpg';
import BannerImage5 from 'src/assets/images/banners/spring-savings-2024/spring-savings-narrow-5.jpg';
import BannerImage6 from 'src/assets/images/banners/spring-savings-2024/spring-savings-narrow-6.jpg';

export type TProductImages = {
	images: {
		id: string
		childImageSharp: {
			gatsbyImageData: IGatsbyImageData
		}
	}[]
}

export type TProductGatsbyImage = TProduct & TProductImages

type TSingleProduct = {
	product: TProductGatsbyImage
	relatedArticles?: TBlogPost[]
}

export const SingleProduct: React.FC<TSingleProduct> = (props) => {
	const theme = useTheme();
	const {
		product,
		relatedArticles,
	} = props;

	const {
		id,
		name,
		videoUrl,
		ingredients,
		suggestedUse,
		warnings,
		description,
		subDescription,
		productInfoPdf,
		faq,
	} = product;
	const [rating, setRating] = useState(0);
	const [reviewCount, setReviewCount] = useState(0);
	const [reviews, setReviews] = useState<{
		name: string
		rating: number
		description: string
		reply?: string
		createdAt: string
	}[]>([]);

	const [percentages, setPercentages] = useState([0, 0, 0, 0, 0]);
	const firstLoad = useRef(true);

	const { search } = useLocation();
	const urlSearchParams = new URLSearchParams(search);
	const currentPage = urlSearchParams.get('page') || '1';

	const fetchReviews = useCallback(async () => {
		fetch(`/api/fetch-reviews?id=${id}&page=${currentPage}`, {
			method: 'GET',
		}).then(res => res.json()).then((data) => {
			setRating(data.rating);
			setReviewCount(data.total);
			setReviews(data.reviews);
			setPercentages(data.percentages);

			if (!firstLoad.current && currentPage) {
				setTimeout(() => {
					window.location.hash = '#reviews';
				}, 100);
			} else {
				firstLoad.current = false;
			}
		});
	}, [id, currentPage]);

	useEffect(() => {
		fetchReviews();
	}, [id, currentPage, fetchReviews]);

	const aboutItems = useMemo(() => [
		{
			title: 'Benefits',
			content: description,
			icon: descriptionIcon,
			id: 'description',
		},
		{
			title: 'Dosage',
			content: suggestedUse,
			icon: suggestedIcon,
		},
		{
			title: 'Ingredients',
			content: ingredients,
			icon: ingredientsIcon,
		},
		{
			title: 'Side Effects',
			content: warnings,
			icon: warningsIcon,
		},
		{
			title: 'Overview',
			content: subDescription,
			icon: overviewIcon,
		},
	], [description, suggestedUse, ingredients, warnings, subDescription]);

	const videoId = videoUrl?.includes('youtu.be') ? videoUrl?.split('youtu.be/')[1] : videoUrl?.split('v=')[1];

	return (
		<>
			<Section
				paddingTop="s48"
				scale
				overflow="hidden"
				styled={css`
					max-width: 100vw;
				`}
			>

				<Flex justifyContent="center">
					<Flex
						direction="column"
						largeTablet={[
							'alignItemsCenter',
							{ maxW: '764px' },
						]}
					>
						<ProductView product={product} rating={rating} reviewCount={reviewCount} />
						<BannerWrapper
							marginTop="s56"
							mobile={[{
								marginTop: 's16',
							}]}
						>
							<picture>
								<source media="(min-width: 1536px)" srcSet={BannerImage2} />
								<source media="(min-width: 1280px)" srcSet={BannerImage3} />
								<source media="(min-width: 992px)" srcSet={BannerImage4} />
								<source media="(min-width: 768px)" srcSet={BannerImage5} />
								<img src={BannerImage6} alt="Spring Savings 2024" />
							</picture>
						</BannerWrapper>
						<Heading
							as="h2"
							marginTop="s56"
							marginBottom="s32"
							mobile={[{
								marginTop: 's16',
								marginBottom: 's20',
							}]}
						>
							{name}
							{' '}
							details
						</Heading>
						{aboutItems.map((item, index) => {
							return item.content ? (
								<Flex
									id={item.id}
									largeTablet={['column']}
									direction="column"
									styled={css`
										margin-bottom: 32px;

										${Wrapper} > p  {
											padding-bottom: 16px;
											strong {
												font-weight: bold;
											}
										}

										${Wrapper} > ul > li {
											list-style: disc;
											margin-left: 24px;
										}

										* {
											line-height: normal;
										}
									`}
								>

									{item.id !== 'description' && (
										<>
											<Heading
												as="h4"
												fontSize="s24"
												fontWeight="bold"
												tablet={[{
													fontSize: 's20',
												}]}
											>
												<Image marginRight="s20" src={item.icon} alt="" w="32px" display="inline" style={{ verticalAlign: 'middle' }} />
												{item.title}
											</Heading>

											<Wrapper
												paddingLeft="s52"
												fontSize="s16"
												largeTablet={[{ marginTop: 's16', paddingLeft: 's0' }]}
												dangerouslySetInnerHTML={{ __html: item.content }}
											/>
										</>
									)}
									{item.id === 'description' && (
										<>
											<Heading
												as="h4"
												fontSize="s24"
												fontWeight="bold"
												tablet={[{
													fontSize: 's20',
												}]}
											>
												<Image marginRight="s20" src={item.icon} alt="" w="32px" display="inline" style={{ verticalAlign: 'middle' }} />
												Description
											</Heading>
											<Wrapper
												paddingLeft="s52"
												fontSize="s16"
												largeTablet={[{ marginTop: 's16', paddingLeft: 's0' }]}
											>
												{item.content}
											</Wrapper>
										</>
									)}
								</Flex>
							) : null;
						})}
						<FocusSectionWrapper edge>
							Our sole focus is on crafting wholesome vitamins and supplements that both Lori and James Atkins themselves trust for their own family.
							We diligently source the finest quality ingredients and carefully formulate delicious products designed to bolster your health and wellness effortlessly!
							<Center marginTop="s32">
								<Flex
									wrap
									direction="row"
									justifyContent="center"
									alignItems="flex-start"
									gap="s24"
									largeTablet={[{
										gap: 's16',
									}]}
								>
									<FocusSectionItem>
										<NoColors />
										<Text>
											No Artificial Colors,
											<br />
											Flavors & Preservatives
										</Text>
									</FocusSectionItem>
									<FocusSectionItem>
										<Compliant />
										<Text>
											FDA-xGMP
											<br />
											Compliant
										</Text>
									</FocusSectionItem>
									<FocusSectionItem>
										<ThirdParty />
										<Text>
											Third-Party
											<br />
											Tested
										</Text>
									</FocusSectionItem>
									<FocusSectionItem>
										<FormulatedByDoctor />
										<Text>
											Formulated
											<br />
											by Doctor
										</Text>
									</FocusSectionItem>
									<FocusSectionItem>
										<NoFillers />
										<Text>
											No
											<br />
											Fillers
										</Text>
									</FocusSectionItem>
								</Flex>
							</Center>

						</FocusSectionWrapper>
						{productInfoPdf && (
							<Center>
								<Link href={productInfoPdf.url} target="_blank" rel="noopener noreferrer" fontSize="s20" fontWeight="semiBold" styled={css`margin-top: 80px; margin-bottom: 80px`}>
									See supplement facts (PDF)
									{' '}
									<Icon as={LuDownload} styled={css`margin-left: 8px; vertical-align: middle;`} />
								</Link>
							</Center>
						)}
						{!!relatedArticles?.length && (
							<Section>
								<Heading
									as="h2"
									textAlign="left"
									marginTop="s40"
								>
									Related articles
								</Heading>
								<Flex
									wrap
									gap="20px"
									padding={['t32']}
									largeTablet={['justifyContentCenter']}
									tablet={[{ gap: '20px' }]}
								>
									{relatedArticles.map(blogPost => (
										<BlogCard
											key={blogPost.title}
											excerpt={blogPost.excerpt}
											slug={blogPost.slug}
											previewImage={blogPost.previewImage}
											title={blogPost.title}
											fields={blogPost.fields}
											publishedAt={blogPost.publishedAt}
										/>
									))}
								</Flex>
								<Center marginTop="s24">
									<ContentfulLink
										page={
											{
												slug: 'blog',
												__typename: 'ContentfulPage',
											}
										}
										title="See all articles"
										linkVariant="withArrow"
										linkColor="primary"
										url=""
										openInNewTab={false}
									/>
								</Center>
							</Section>
						)}
					</Flex>
				</Flex>
			</Section>
			{videoId && (
				<Wrapper
					styled={css`
					background-color: ${({ theme }) => theme.color.bgLight};
				`}
					marginTop="s48"
					marginBottom="s48"
					paddingTop="s48"
					paddingBottom="s48"
					mobile={[{
						paddingTop: 's32',
						paddingBottom: 's32',
						marginTop: 's0',
						marginBottom: 's32',
					}]}
				>
					<Section
						scale
					>
						<Flex direction="column" alignItems="center">
							<Heading as="h3" marginBottom="s16" textAlign="center">First hand advice</Heading>
							<Heading as="h2" marginBottom="s16" textAlign="center">What did we learn from our practice?</Heading>

							<ContentfulEmbedVideo
								title={name}
								videoId={videoId}
								fluid
								height="330px"
								styled={css`
										& > div {
											border-radius: 8px;
										}
										.play-button-wrapper {
											bottom: 20px;
											right: 20px;
											* {
												font-size: ${theme.fontSize.s16};
											}
										}
										.embedded-video-title {
											font-size: ${theme.fontSize.s20};
											top: 20px;
											left: 20px;
										}
									`}
							/>
							<Paragraph marginTop="s40" marginBottom="s32" textAlign="center" maxW="600px">
								Still have doubts about
								{' '}
								{name}
								? Send your question and we will respond within 24h.
							</Paragraph>
							<Button
								size="small"
								as={GatsbyLink}
								to="/contact-us"
							>
								Ask Lori
							</Button>
						</Flex>
					</Section>
				</Wrapper>
			)}
			{faq && faq?.length > 0 && (
				<Section
					scale
					marginTop="s48"
					mobile={[{
						marginTop: 's32',
					}]}
				>
					<Heading as="h2" marginBottom="s48" textAlign="left">
						Frequently asked questions
					</Heading>
					{faq?.map((item, index) => {
						return (
							<Accordion
								hideLine={index === faq.length - 1}
								hoverColor="bgLight"
								titleAs="h3"
								isTimeline
								title={item.question}
								content={item.answer}
								contentSize="small"
							/>
						);
					})}
				</Section>
			)}
			<Section
				scale
				marginTop="s48"
				marginBottom="s48"
				mobile={[{
					marginTop: 's32',
					marginBottom: 's40',
				}]}
			>
				<Heading id="reviews" as="h2" marginBottom="s32" textAlign="left">Here&apos;s what our customers say</Heading>
				{/* <Heading as="h2" marginBottom="s56" textAlign="left" fontSize="s24">
					{name}
					{' '}
					reviews
				</Heading> */}
				<Reviews product={product} reviews={reviews} percentages={percentages} rating={rating} reviewCount={reviewCount} currentPage={currentPage} fetchReviews={fetchReviews} />

			</Section>
			<Section
				scale
				marginBottom="s48"
				mobile={[{
					marginBottom: 's32',
				}]}
			>
				<Heading id="reviews" as="h2" marginBottom="s32" textAlign="left">You may also like</Heading>

				<ContentfulProductsSlider
					removeProductId={id}
				/>
			</Section>
		</>
	);
};

SingleProduct.defaultProps = {
	filter: undefined,
	relatedArticles: undefined,
};
