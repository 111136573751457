import React, { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

// Molecules
import HeadMeta from 'src/components/Molecules/HeadMeta/HeadMeta';

// Types
import { TBlogPost, TProduct } from 'src/utils/types';
import { TContentfulSection } from '../utils/types';

// Sections
import { SingleProduct } from 'src/components/Sections/Product';
import SectionBuilder from 'src/contentful/SectionBuilder';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { trackFB } from 'src/utils/gtag';

type TProductPage = {
	data: {
		product: TProduct
		bannerSection: TContentfulSection,
		allFile: {
			nodes: {
				id: string
				childImageSharp: {
					gatsbyImageData: IGatsbyImageData
				}
			}[]
		}
		metaImage: {
			nodes: {
				id: string
				childImageSharp: {
					gatsbyImageData: IGatsbyImageData
				}
			}[]
		}
		allContentfulBlogPost: {
			nodes: TBlogPost[]
		},
	}

}

const Product: React.FC<TProductPage> = (props) => {
	const { state } = useLocation();
	const {
		data: {
			product,
			allFile,
			bannerSection,
			allContentfulBlogPost,
		},
	} = props;

	const relatedArticles = allContentfulBlogPost?.nodes;

	useEffect(() => {
		trackFB('ViewContent', {
			content_name: product.name,
			currency: 'USD',
			value: product.variants[0].discountedPrice,
			content_type: 'product',
		});
	}, [product]);

	const sortedImages = useMemo(() => allFile.nodes.slice().sort((a, b) => a.id.localeCompare(b.id)), [allFile]);

	if (!product) return null;
	const extendedProduct = {
		...product,
		images: sortedImages,
	};
	return (
		<>
			<SingleProduct product={extendedProduct} relatedArticles={relatedArticles} />
			{SectionBuilder([bannerSection])}
		</>
	);
};

export default Product;

export const pageQuery = graphql`
    query SingleProductQuery ($reg: String!, $id: String!, $relatedArticles: [String]!) {
		product: products(id: {eq: $id}) {
			...ProductFields
		}
		allFile(filter: {id: {regex: $reg}}) {
			nodes{
				id
				childImageSharp {
					gatsbyImageData(layout: CONSTRAINED , placeholder: BLURRED, formats: [AUTO, AVIF])
				}
			}
		}
		metaImage: allFile(filter: {id: {regex: $reg}}) {
			nodes{
				id
				childImageSharp {
					gatsbyImageData(width: 1200,layout: CONSTRAINED , placeholder: BLURRED, formats: [AUTO, AVIF])
				}
			}
		}
		bannerSection: contentfulSection(identifier: {eq: "\"NEW\" Banner Section (Universal)"}) {
			...SectionFields
		}
		allContentfulBlogPost(limit: 3, filter: {slug: {in: $relatedArticles}}) {
		 	nodes {
		 		author {
		 			...AuthorFields
		 		}
		 		slug
		 		excerpt
		 		title
		 		previewImage {
		 			...AssetFields
		 			gatsbyImageData(placeholder: BLURRED)
		 		}
		 		publishedAt(formatString: "MMM DD, YYYY")
		 		fields {
		 			readingTime
		 		}
		 	}
		}
	}
`;

export const Head: React.FC<TProductPage> = (props) => {
	const {
		data: {
			product,
			metaImage: { nodes: metaImage },
		},
	} = props;

	metaImage.sort((a, b) => a.id.localeCompare(b.id));
	// const {
	// 	getAvailableQuantity,
	// } = useAppContext();

	// const { variants } = product;
	// const {
	// 	id: variantId,
	// } = variants[0];

	// const isOutOfStock = getAvailableQuantity(variantId) <= 0;

	if (!product) return null;
	const metaImageSrc = metaImage?.[0]?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || '';

	const isImageStatic = metaImageSrc.startsWith('/static/');

	return (
		<HeadMeta
			meta={{
				pageTitle: `${product.name}${product.subTitle ? `, ${product.subTitle}` : ''}`,
				metaDescription: product.description,
				metaImage: metaImageSrc,
				structuredData: {
					'@context': 'https://schema.org',
					'@type': 'Product',
					name: `${product.name}${product.subTitle ? `, ${product.subTitle}` : ''}`,
					description: product.description,
					image: isImageStatic ? `${process.env.SITE_URL}${metaImageSrc}` : metaImageSrc || undefined,
					// availability: isOutOfStock ? 'out_of_stock' : 'in_stock',
					offers: {
						'@type': 'Offer',
						price: product.variants[0].discountedPrice.toFixed(2),
						priceCurrency: 'USD',
						itemCondition: 'https://schema.org/NewCondition',
						// availability: isOutOfStock ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock',
						availability: 'https://schema.org/InStock',
					},
				},
				isCanonical: true,
			}}
		/>
	);
};
