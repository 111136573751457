import styled from 'styled-components';

export const RegularPrice = styled.span`
    font-size: 28px;
    position: relative;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    font-weight: 600;
`;

export const RegularPriceCrossed = styled(RegularPrice)`
    font-size: 22px;

    &:after {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: 0;
        right: 0;
        height: 2px;
        border-radius: 1px;
        background: ${props => props.theme.color.discountedPrice};
    }
`;

export const DiscountedPrice = styled(RegularPrice)`
    font-size: 24px;
    color: ${props => props.theme.color.discountedPrice};
`;
