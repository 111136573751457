/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import Ratings from 'fatcat-ui-library/components/Molecules/Ratings/Ratings';
import React, { useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import {
	ErrorText,
	Percentage, PercentageWrapper, ProgressBar, Reply, ReviewImage, ReviewWrapper, SecondaryText, UploadArea, UploadImage,
} from './Reviews.atoms';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import LogoAvatar from 'src/assets/images/logo-avatar.svg';
import Avatar from 'fatcat-ui-library/components/Molecules/Avatar/Avatar';
import Pagination from 'src/components/Organisms/Pagination/Pagination';
import Modal from '../Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from 'fatcat-ui-library/components/Atoms/Input';
import * as yup from 'yup';
import { TextArea } from 'fatcat-ui-library/components/Atoms/TextArea';
import { TProduct } from 'src/utils/types';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import ImageUploading from 'react-images-uploading';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { LuTrash2 } from 'react-icons/lu';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

type TReviews = {
	reviews: {
		name: string
		rating: number
		description: string
		reply?: string
		createdAt: string
		images?: { urls: {
			'256x192': string
			'2048x1536': string
		}}[]
	}[]
	percentages: number[]
	reviewCount: number
	rating: number
	currentPage: string
	product: TProduct
	fetchReviews: () => void
}

const schema = yup.object().shape({
	name: yup.string().required('Requierd field!'),
	description: yup.string().required('Requierd field!'),
	rating: yup.number().required('Requierd field!'),
	recaptcha: yup.string().required('Please confirm you are not a robot!'),
});

type FormValues = {
	name: string
	description: string
	rating: string
	recaptcha: string
}

const Reviews: React.FC<TReviews> = ({
	reviews, rating, reviewCount, currentPage, percentages, product: { id }, fetchReviews,
}) => {
	const [images, setImages] = useState([]);
	const [isImageModalOpen, setIsImageModalOpen] = useState(false);
	const [zoomedImage, setZoomedImage] = useState(null);

	const [isCreateReviewOpen, setIsCreateReviewOpen] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm<FormValues>({ resolver: yupResolver(schema) });

	const { search, pathname } = useLocation();

	const [isSending, setIsSending] = useState(false);

	const onUploadChange = (imageList, addUpdateIndex) => {
		// data for submit
		setImages(imageList);
	};

	const onSubmitHandler: SubmitHandler<FormValues> = async (data) => {
		setIsSending(true);
		try {
			const ids = await Promise.all(images.map(async (image: {data_url: string}) => {
				return fetch(image.data_url)
					.then(res => res.blob())
					.then(async (blob) => {
						const fd = new FormData();
						fd.append('file', blob);

						const response = await fetch(`${process.env.GATSBY_API_URL}/images/reviews`, {
							method: 'POST',
							body: fd,
						});

						const json = await response.json();
						return json.id;
					});
			}));

			await fetch(`/api/send-review?id=${id}`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					...data,
					images: ids,
				}),
			}).then((res) => {
				if (!res.ok) {
					throw new Error('Your request could not be processed!');
				}
			});
			reset();
			setIsCreateReviewOpen(false);
			const urlSearchParams = new URLSearchParams(search);
			const currentPage = urlSearchParams.get('page') || '1';
			if (currentPage === '1') {
				fetchReviews();
			} else {
				urlSearchParams.set('page', '1');
			}
			const newSearch = urlSearchParams.toString();
			navigate(newSearch ? `?${newSearch}` : pathname);
		} catch (error) {
			console.error(error);
		}
		setIsSending(false);
	};

	const handleVerify = useCallback(
		(token: string) => {
			setValue('recaptcha', token);
		},
		[setValue]
	);

	const createReviewModal = (
		<Modal
			className="modal create-review"
			isOpen={isCreateReviewOpen}
			closeModal={() => { setIsCreateReviewOpen(false); }}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			onRequestClose={() => { setIsCreateReviewOpen(false); }}
		>
			<Flex
				as="form"
				direction="column"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				<Heading as="h3" marginBottom="s24">Create a review</Heading>
				<Input
					{...register('name')}
					placeholder="Your name"
					errorMsg={errors.name?.message as string}
					fontWeight="medium"
				/>
				<Wrapper marginBottom="s20">
					<Ratings
						{...register('rating')}
						maxRating={5}
						getRating={rating => setValue('rating', `${rating}`)}
					/>
					{errors.rating?.message && <ErrorText>{errors.rating?.message}</ErrorText> }
				</Wrapper>
				<TextArea
					{...register('description')}
					rows={6}
					placeholder="Review"
					errorMsg={errors.description?.message as string}
					fontWeight="medium"
				/>
				Images
				{' '}
				<br />
				<br />
				<ImageUploading
					multiple
					value={images}
					onChange={onUploadChange}
					maxNumber={5}
					dataURLKey="data_url"
				>
					{({
						imageList,
						onImageUpload,
						onImageRemoveAll,
						onImageUpdate,
						onImageRemove,
						isDragging,
						dragProps,
					}) => (
						// write your building UI
						<UploadArea
							onClick={onImageUpload}
							style={isDragging ? { borderColor: '#1A6B50' } : undefined}
							{...dragProps}
						>
							{imageList.length === 0 && (
								<>
									Click or drag here to upload
								</>
							)}
							<Flex direction="row">
								{imageList.map((image, index) => (
									<UploadImage key={index}>
										<img
											src={image.data_url}
											alt=""
											style={{
												height: '100px',
											}}
										/>
										<div />
										<button
											type="button"
											onClick={(e) => {
												onImageRemove(index);
												e.preventDefault();
												e.stopPropagation();
											}}
										>
											<Icon as={LuTrash2} w="16px" h="auto" />
										</button>
									</UploadImage>
								))}
							</Flex>
						</UploadArea>
					)}
				</ImageUploading>
				<GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={isCreateReviewOpen} />
				<Input type="hidden" {...register('recaptcha')} />
				{errors.recaptcha?.message && <ErrorText>{errors.recaptcha?.message}</ErrorText> }
				<Button
					type="submit"
					size="small"
					marginTop="s24"
					w="fit-content"
				>
					{isSending && 'Sending review...'}
					{!isSending && 'Send review'}
				</Button>
			</Flex>
		</Modal>
	);

	if (reviewCount === 0) {
		return (
			<>
				<div className="text-center">
					<Heading as="h4" marginBottom="s16">No reviews yet</Heading>
					<Paragraph fontSize="s16">
						Be the first to review this product
					</Paragraph>
					<Button
						size="small"
						to="/contact-us"
						marginTop="s24"
						w="fit-content"
						onClick={() => { setIsCreateReviewOpen(true); }}
					>
						Write a Review
					</Button>
				</div>
				{createReviewModal}
			</>
		);
	}

	return (
		<Flex direction="row" tablet={['columnReverse']}>
			<Flex direction="column" w="40%" fontSize="s14" tablet={[{ w: '100%' }]}>
				<Heading as="h4" marginBottom="s16">Customer reviews</Heading>
				<Flex direction="row" alignItems="center" marginBottom="s16">
					<Flex direction="row" alignItems="center">
						<Ratings rating={rating || 0.01} size="12px" gap="4px" />
						<Text marginLeft="s16" fontSize="s20" fontWeight="semiBold">
							{rating.toFixed(1)}
							<SecondaryText fontSize="s16"> / 5</SecondaryText>
						</Text>
					</Flex>
					<Wrapper textAlign="right">
						<SecondaryText>
							{reviewCount}
							{' '}
							{reviewCount > 1 ? 'Reviews' : 'Review'}
						</SecondaryText>
					</Wrapper>
				</Flex>
				<PercentageWrapper>
					{[...percentages].reverse().map((percentage, index) => (
						<Flex marginBottom="s14" alignItems="center">
							<Text marginRight="s24" w="90px">
								{5 - index}
								{' '}
								Stars
							</Text>
							<ProgressBar>
								<div style={{ width: `${percentage}%` }} />
							</ProgressBar>
							<Percentage>
								{percentage.toFixed(0)}
								%
							</Percentage>
						</Flex>
					))}
				</PercentageWrapper>
				<Heading as="h4" marginBottom="s16">Review this product</Heading>
				<Paragraph fontSize="s16">
					Share your thoughts with other customers
				</Paragraph>
				<Button
					size="small"
					to="/contact-us"
					marginTop="s24"
					w="fit-content"
					onClick={() => { setIsCreateReviewOpen(true); }}
				>
					Write a Review
				</Button>
			</Flex>
			<Flex direction="column" w="60%" paddingLeft="s64" tablet={[{ w: '100%', paddingLeft: 's0' }]}>
				<Wrapper>
					{reviews.map(review => (
						<ReviewWrapper>
							<Flex direction="row" alignItems="center" marginBottom="s16">
								<Flex direction="column">
									<Text marginBottom="s8" fontWeight="semiBold">
										{review.name}
									</Text>
									<Ratings rating={review.rating} size="12px" gap="4px" />
								</Flex>
								<Wrapper textAlign="right">
									<SecondaryText fontSize="s14" fontWeight="semiBold">
										{ new Date(review.createdAt).toLocaleDateString('en-US', {
											weekday: undefined,
											month: 'long',
											day: 'numeric',
											year: 'numeric',
										})}
									</SecondaryText>
								</Wrapper>
							</Flex>
							<Paragraph>
								{review.description}
							</Paragraph>
							{(review.images?.length || 0) > 0 && (
								<Paragraph marginTop="s16">
									{review.images?.map(image => (
										<ReviewImage
											src={image.urls['256x192']}
											alt="Review image"
											onClick={() => {
												setZoomedImage(image.urls['2048x1536']);
												setIsImageModalOpen(true);
											}}
										/>
									))}
								</Paragraph>
							)}
							{review.reply && (
								<Reply>
									<Avatar image={LogoAvatar} size="40px" />
									<Flex direction="column" marginLeft="s16">
										<Text fontWeight="semiBold" marginBottom="s8">
											Enjoy Good Health
										</Text>
										<Paragraph>
											{review.reply}
										</Paragraph>
									</Flex>

								</Reply>
							)}
						</ReviewWrapper>
					))}
				</Wrapper>
				<Pagination currentPage={Number(currentPage)} numPages={Math.ceil(reviewCount / 10)} />
				{createReviewModal}
				<Modal
					className="modal image-expand-modal"
					isOpen={isImageModalOpen}
					closeModal={() => { setIsImageModalOpen(false); }}
					shouldCloseOnEsc
					shouldCloseOnOverlayClick
					onRequestClose={() => { setIsImageModalOpen(false); }}
				>
					{isImageModalOpen && (
						<TransformWrapper
							centerZoomedOut
						>
							<TransformComponent
								contentStyle={{
									height: '100%',
									width: '100%',
								}}
							>
								{zoomedImage && <img src={zoomedImage} alt="" />}

							</TransformComponent>
						</TransformWrapper>
					)}
				</Modal>
			</Flex>
		</Flex>
	);
};

export default Reviews;
