/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import { useAppContext } from 'src/context/appContext';
import { useCart } from 'react-use-cart';
import styled, { css, useTheme } from 'styled-components';
import { Link as GatsbyLink, navigate } from 'gatsby';

import expand from 'src/assets/images/icons/expand.svg';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import Ratings from 'fatcat-ui-library/components/Molecules/Ratings';

// Atoms
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
// Molecules

// Types
import { TProductGatsbyImage } from 'src/components/Sections/Product';

// Utils
import {
	formatProductForCart,
	getCartItemQuantity,
} from 'src/utils/helpers';
import { itemsFormat, trackAnalytic, trackFB } from 'src/utils/gtag';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { rgba } from 'polished';
import Modal from '../Modal';
import Labels from '../Labels/Labels';
import Price from '../Price/Price';
import {
	AddToCartWrapper, DetailsList, ProductHeading, SwiperContainer,
} from './ProductView.atoms';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

import {
	TransformComponent, TransformWrapper,
} from 'react-zoom-pan-pinch';
import Counter from '../Counter';
import TransformWrapperNoPan from './TransformWrapperNoPan';
import { LuChevronLeft } from 'react-icons/lu';
import ContentfulEmbedVideo from 'src/contentful/ContentfulEmbedVideo';
import ContentfulEmbedVideoThumbnail from 'src/contentful/ContentfulEmbedVideo/thumbnail';
import RadioLabel from '../RadioLabel';
import Select, { SingleValue } from 'react-select';

type TProductView = {
	product: TProductGatsbyImage
	rating: number
	reviewCount: number
}

type TOptionLabel = {
	value: string
	label: string
};

const ProdcutViewWrapper = styled(Flex)`

	${props => props.theme.media.mobile} {
		.swiper-slide {
			display: flex;
			justify-content: center;
		}
	}

	.swiper-pagination {
		position: relative !important;
		top: -48px !important;
	}

	.swiper-pagination-bullet {
		background-color: ${props => props.theme.color.lightGray};
		height: 5px;
		width: 5px;
		margin: 0 4px !important;
		opacity: 1 !important;
	}

	.swiper-pagination-bullet-active {
		background-color: ${props => props.theme.color.primary};
		width: 20px;
		border-radius: 20px;
	}
	
	.swiper-navigation {
		display: none;
		position: absolute;
		cursor: pointer;
		font-size: 18px;
		svg {
			vertical-align: middle;
			height: 30px;
			width: 30px;
		}

		&.swiper-navigation-left {
			left: -32px;
			top: 50%;
		}

		&.swiper-navigation-right {
			right: -32px;
			top: 50%;
		}


		span {
			display: inline-block;
			line-height: 20px;
			vertical-align: middle;
			margin-top: 2px;
		}

		z-index: 20;

		&.swiper-button-disabled {
			color: ${props => props.theme.color.darkerGrey};
		}

		&:not(.swiper-button-disabled):hover {
			color: ${props => props.theme.color.primary};
		}
	}

	

	
	.swiper-expand {
		${props => props.theme.media.mobile} {
			display: none;
		}

		display: block;
		width: auto;
		color: ${props => props.theme.color.lightGray};
		text-align: center;
		position: absolute;
		bottom: 24px;
		right: 24px;
		z-index: 20;
		cursor: pointer;
		align-items: center;
		justify-content: center;

		&:hover {
			color: ${props => props.theme.color.primary};
			.swiper-expand-icon {
				background-color: ${props => props.theme.color.primary};
			}
		}

		.swiper-expand-icon {
			box-sizing: border-box;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			padding: 6px;
			background-color: ${props => rgba(props.theme.color.darkestGray, 0.5)};
		}
	}
`;

const ProductView: React.FC<TProductView> = ({ product, rating, reviewCount }) => {
	const mixpanel = useMixpanel();
	const {
		addItem, items,
	} = useCart();
	const {
		getAvailableQuantity,
		toggleCart,
		navOpen,
		setNavOpen,
	} = useAppContext();
	const [activeIndex, setActiveIndex] = useState(0);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [selectedMode, setSelectedMode] = useState('one-time');
	const [interval, setInterval] = useState<SingleValue<TOptionLabel>>({
		value: '30',
		label: 'Every 30 days',
	});
	// const [isAddToCartVisible, setIsAddToCartVisible] = useState(false);

	// useEffect(() => {
	// 	const observer = new IntersectionObserver((entries) => {
	// 		entries.forEach((entry) => {
	// 			if (!entry.isIntersecting) {
	// 				setIsAddToCartVisible(true);
	// 			} else {
	// 				setIsAddToCartVisible(false);
	// 			}
	// 		});
	// 	}, {
	// 		threshold: 1,
	// 	});
	// 	const el = document.querySelector('#product-view-swiper');
	// 	if (el) {
	// 		observer.observe(el);
	// 	}
	// 	return () => {
	// 		observer.disconnect();
	// 	};
	// }, []);

	const {
		name,
		subTitle,
		images,
		subDescription,
		productLabels,
		variants,
	} = product;
	const {
		id: variantId, videoUrl, isRecurringPurchaseEligible, recurringPurchaseDiscount,
	} = variants[0];
	const videoId = videoUrl?.includes('youtu.be') ? videoUrl?.split('youtu.be/')[1] : videoUrl?.split('v=')[1];
	const theme = useTheme();

	const currentInCart = getCartItemQuantity(items, variantId);
	const availableQuantity = useMemo(() => getAvailableQuantity(variantId) - currentInCart, [currentInCart, getAvailableQuantity, variantId]);
	const isOutOfStock = availableQuantity <= 0;
	const [count, setCount] = useState(() => isOutOfStock ? 0 : 1);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleCart = () => {
		toggleCart();
		if (navOpen) {
			setNavOpen(false);
			document.body.classList.remove('html-block-scrolling');
		}
	};

	const addingItemsToCart = () => {
		trackAnalytic('add_to_cart', {
			items: itemsFormat(items),
			item_added: {
				id: variantId,
				name,
			},
		});
		trackFB('AddToCart', {
			content_type: 'product',
			contents: [
				{
					id: variantId,
					name,
					quantity: count,
				},
			],
			value: product.variants[0].discountedPrice.toFixed(2),
			currency: 'USD',
		});
		addItem(formatProductForCart(product, selectedMode, Number(interval?.value)), count);
		mixpanel.track('Add to Cart', {
			'Product Name': name,
			'Product ID': variantId,
			'Product Price': product.variants[0].discountedPrice.toFixed(2),
			'Product Quantity': count,
		});
	};

	const handleAddToCart = () => {
		addingItemsToCart();
		setCount(1);
		handleCart();
	};

	const websiteImages = useMemo(() => {
		const wi = images.filter(item => item.id.includes('website'));
		return wi.length ? wi : images;
	}, [images]);

	const subDescriptionParagraphs = useMemo(() => {
		if (!subDescription) return [];
		return subDescription.split('</p>').filter(item => item.trim() !== '').map(item => `${item}</p>`);
	}, [subDescription]);
	return (
		<ProdcutViewWrapper
			largeTablet={['column']}
		>

			<Flex
				direction="column"
				w="50%"
				largeTablet={[{ w: '100%' }]}
				marginBottom="s16"
			>
				<Wrapper
					largeTablet={[{ marginBottom: 's16' }]}
					w="50%"
					marginBottom="s16"
					display="none"
					mobile={[{ display: 'block' }]}
				>
					<Link
						as={GatsbyLink}
						to="/products/"
						onClick={() => {
							if (window.prevLocation) {
								navigate(-1);
							}
						}}
						fontWeight="medium"
						fontSize="s16"
						display="flex"
						alignSelf="center"
						mobile={[{ fontSize: 's14' }]}
						styled={css` align-items: center; `}
					>
						<Icon
							as={LuChevronLeft}
							w="16px"
							h="auto"
							margin={['r10', 'b2']}
						/>
						Back to Products
					</Link>
				</Wrapper>
				<Flex
					mobile={[{ maxW: '100vw', w: '100vW' }]}
					styled={css`
						${props => props.theme.media.mobile} {
							margin: 0 -20px 0 -20px;
						}
					`}
				>
					<Flex
						direction="column"
						justifyContent="center"
					>
						<Center>
							<SwiperContainer
								id="product-view-swiper"
							>
								<Swiper
									modules={[Thumbs]}
									thumbs={{ swiper: thumbsSwiper }}
									loop
									onSlideChange={(swiper) => {
										setActiveIndex(swiper.realIndex);
									}}
									followFinger={false}
									spaceBetween={32}
									autoHeight
								>
									{videoId && (
										<SwiperSlide
											key={videoId}
										>
											<Wrapper
												overflow="hidden"
											>
												<Flex
													overflow="hidden"
												>
													<ContentfulEmbedVideo
														videoId={videoId}
														title={name}
														fluid
														styled={css`
															& > div {
																border-radius: 8px;
															}
															.play-button-wrapper {
																bottom: 20px;
																right: 20px;
																* {
																	font-size: ${theme.fontSize.s16};
																}
															}
															.embedded-video-title {
																font-size: ${theme.fontSize.s20};
																top: 20px;
																left: 20px;
															}
														`}
													/>
												</Flex>
											</Wrapper>
										</SwiperSlide>
									)}

									{websiteImages.map(item => (
										<SwiperSlide
											key={item.id}
										>

											<Wrapper
												overflow="hidden"
												styled={css`
													border-radius: 20px;

													${props => props.theme.media.mobile} {
														border-radius: 0;
													}
												`}
											>
												<Flex
													overflow="hidden"
													h="618px"
													w="100%"
													mobile={[{ display: 'none' }]}
												>
													<GatsbyImage alt={`${name}, ${subTitle}`} image={item.childImageSharp?.gatsbyImageData} objectFit="cover" />
												</Flex>
												<Flex
													overflow="hidden"
													w="100%"
													h="450px"
													display="none"
													mobile={[{ display: 'flex' }]}
												>
													<TransformWrapperNoPan
														onZoom={() => {
															const el = document.querySelector('.swiper-slide-active .zoomable-image picture img');
															if (el) {
																(document.querySelector('.swiper-slide-active .zoomable-image picture img') as HTMLImageElement)
																	.src = item.childImageSharp?.gatsbyImageData?.images?.fallback?.src || '';
																(document.querySelector('.swiper-slide-active .zoomable-image picture img') as HTMLImageElement)
																	.removeAttribute('srcset');
															}
														}}
													>
														<TransformComponent
															contentStyle={{
																height: 450,
															}}
														>
															<GatsbyImage className="zoomable-image" alt={`${name}, ${subTitle}`} image={item.childImageSharp?.gatsbyImageData} objectFit="cover" />

														</TransformComponent>
													</TransformWrapperNoPan>
												</Flex>
												<Flex role="link" tabIndex={0} className="swiper-expand" onClick={() => setIsModalOpen(true)}>
													<div className="swiper-expand-icon">
														<Image
															alt="expand"
															src={expand}
															w="20px"
															h="20px"
															height="20"
															width="20"
														/>
													</div>
												</Flex>
											</Wrapper>

										</SwiperSlide>
									))}

								</Swiper>
								<Flex
									marginTop="s16"
								>
									<Swiper
										modules={[Thumbs]}
										watchSlidesProgress
										onSwiper={setThumbsSwiper}
										slidesPerView={6}
										width={375}
									>
										{videoId && (
											<SwiperSlide
												key={videoId}
											>
												<Wrapper
													overflow="hidden"
												>
													<Flex
														overflow="hidden"
													>
														<ContentfulEmbedVideoThumbnail
															videoId={videoId}
															title={name}
															fluid
														/>
													</Flex>
												</Wrapper>
											</SwiperSlide>
										)}
										{websiteImages.map(item => (
											<SwiperSlide key={item.id}>
												<GatsbyImage
													alt={name}
													image={item.childImageSharp?.gatsbyImageData}
													objectFit="cover"

												/>
											</SwiperSlide>
										))}
									</Swiper>
								</Flex>
							</SwiperContainer>
							<Modal
								className="modal image-expand-modal"
								isOpen={isModalOpen}
								closeModal={() => { setIsModalOpen(false); }}
								shouldCloseOnEsc
								shouldCloseOnOverlayClick
								onRequestClose={() => { setIsModalOpen(false); }}
							>
								{isModalOpen && (
									<TransformWrapper>
										<TransformComponent
											contentStyle={{
												height: '100%',
												width: '100%',
											}}
										>
											<GatsbyImage alt={name} image={websiteImages[activeIndex].childImageSharp?.gatsbyImageData} objectFit="cover" />

										</TransformComponent>
									</TransformWrapper>
								)}
							</Modal>
						</Center>
					</Flex>
				</Flex>
			</Flex>
			<Flex
				direction="column"
				w="50%"
				paddingLeft="s32"
				largeTablet={['clearLeftSideSpacing', 'fluid']}
			>
				<Wrapper>
					<ProductHeading
						as="h2"
						fontWeight="bold"
						fontSize="s32"
						marginBottom="s8"
					>
						{name}
					</ProductHeading>
					<Heading as="h4" fontWeight="normal" fontSize="s16">
						{subTitle}
					</Heading>
					<Paragraph marginBottom="s8" marginTop="s8" size="small">
						<Flex alignItems="center" justifyContent="flex-start">
							{reviewCount > 0 && rating && (
								<Ratings
									rating={rating}
									size="12px"
									maxRating={5}
									gap="4px"
								/>
							)}
							{reviewCount > 0 && (
								<Text marginRight="s16" marginLeft="s8">
									{rating.toFixed(1)}
								</Text>
							)}
							<Link to="#reviews">
								{reviewCount === 0 ? 'No' : reviewCount}
								{' '}
								{reviewCount === 1 ? 'review' : 'reviews'}
							</Link>
						</Flex>
					</Paragraph>
					{productLabels.length > 0 && (
						<Wrapper
							marginTop="s8"
						>
							<Labels items={productLabels} />
						</Wrapper>
					)}

				</Wrapper>
				<AddToCartWrapper
					marginTop="s16"
					className="add-to-cart-visible"
				>
					{isRecurringPurchaseEligible && (
						<Flex
							direction="row"
							gap="8px"
							justifyContent="space-between"
							paddingBottom="s16"
							mobile={['column', {
								alignItems: 'flex-start', gap: '16px', paddingLeft: 's8', paddingTop: 's8',
							}]}
						>
							<RadioLabel
								name="purchaseMode"
								value="one-time"
								checked={
									selectedMode === 'one-time'
								}
								onClick={() => setSelectedMode('one-time')}
							>
								One time purchase
							</RadioLabel>
							<RadioLabel
								name="purchaseMode"
								value="subscribe"
								checked={
									selectedMode === 'subscribe'
								}
								onClick={() => setSelectedMode('subscribe')}
							>
								Subscribe & Get Free Shipping
							</RadioLabel>
						</Flex>
					)}
					<Wrapper
						padding={['b8']}
						tablet={[{ fontSize: 's20' }]}
						styled={css`
							${props => props.theme.media.mobile} {
								text-align: center;
							}
						`}
					>
						<Text
							fontWeight="semiBold"
							fontSize="s24"
						>
							<Price discountedPrice={variants[0].discountedPrice} price={variants[0].price} />
						</Text>
					</Wrapper>

					<Flex
						justifyContent="flex-start"
						alignItems="flex-end"
						gap={selectedMode === 'subscribe' ? '8px' : '16px'}
						direction="row"
						mobile={['alignItemsCenter', selectedMode === 'subscribe' ? 'column' : 'row']}
					>
						<Flex direction="row" gap={selectedMode === 'subscribe' ? '8px' : '16px'} w="auto">
							<Counter
								count={count}
								increment={() => {
									if (count < availableQuantity) {
										setCount(count + 1);
									}
								}}
								decrement={() => {
									if (count > 1) {
										setCount(count - 1);
									}
								}}
								incrementDisabled={isOutOfStock || count >= availableQuantity}
								disabled={isOutOfStock}
								setCount={(value) => {
									if (value > 0 && value <= availableQuantity) {
										setCount(value);
									}
								}}
							/>
							{selectedMode === 'subscribe' && (
								<Select
									value={interval}
									placeholder="Interval"
									options={[{
										label: 'Every 30 days',
										value: '30',
									},
									{
										label: 'Every 60 days',
										value: '60',
									},
									{
										label: 'Every 90 days',
										value: '90',
									},
									{
										label: 'Every 120 days',
										value: '120',
									},
									{
										label: 'Every 180 days',
										value: '180',
									},
									]}
									onChange={(value) => {
										setInterval(value);
									}}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderRadius: '32px',
											borderColor: state.isFocused ? theme.color.primary : theme.color.grey200,
											':hover': {
												borderColor: theme.color.primary,
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											minHeight: '32px',
											padding: '4px 4px 4px 8px',
										}),
										placeholder: (baseStyles, state) => ({
											...baseStyles,
											fontSize: theme.fontSize.s16,
											paddingLeft: '8px',
										}),
										singleValue: (baseStyles, state) => ({
											...baseStyles,
										}),
										indicatorsContainer: (baseStyles, state) => ({
											...baseStyles,
											borderRadius: '50%',
											padding: '4px 4px 4px 0px',
											cursor: 'pointer',
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: '8px 4px 8px 4px',
										}),
										menuList: (baseStyles, state) => ({
											...baseStyles,
											textAlign: 'left',
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isSelected ? theme.color.primary : 'transparent',
											':active': {
												backgroundColor: theme.color.primaryLight,
											},
										}),
									}}
								/>
							)}
						</Flex>
						{isOutOfStock ? (
							<Button
								h="46px"
								w="fit-content"
								disabled
								size="small"
							>
								Out of stock
							</Button>
						) : (
							<Flex
								w="fit-content"
								position="relative"
							>
								<Button
									paddingTop="s2"
									paddingBottom="s0"
									h="46px"
									w="fit-content"
									size="small"
									variant="primary"
									onClick={handleAddToCart}
									fontWeight="semiBold"
								>
									Add to cart
								</Button>
							</Flex>

						)}
					</Flex>
					<DetailsList>
						{selectedMode === 'one-time' && <li>Regular shipping fee of $4.99 will be added to your total at checkout</li>}
						<li>Expected delivery time: 5-7 working days</li>
						{selectedMode === 'one-time' && <li>FREE Shipping for orders over $35</li>}
						{selectedMode === 'subscribe' && <li>FREE Shipping</li>}
						<li>Upgrade to expedited shipping at checkout</li>
					</DetailsList>
				</AddToCartWrapper>

				{subDescriptionParagraphs.length > 0 && (
					<>
						<Paragraph
							size="small"
							dangerouslySetInnerHTML={{ __html: subDescriptionParagraphs[0] }}
							styled={css`
							ul {
								display: block;
								list-style-type: disc;
								margin-top: 1em;
								margin-bottom: 1 em;
								margin-left: 0;
								margin-right: 0;
								padding-left: 40px;
							}
							p {
								display: block; margin-top: 1em; margin-bottom: 1em; margin-left: 0; margin-right: 0;
							}
						`}
						/>
						{subDescriptionParagraphs.length > 1 && (
							<Link
								to="#overview"
								fontSize="s16"
								fontWeight="semiBold"
							>
								Continue reading
							</Link>

						)}
					</>
				)}
				{/*
				<Wrapper marginBottom="s24" marginTop="s24">
					<Certificates />
				</Wrapper> */}

			</Flex>

		</ProdcutViewWrapper>
	);
};

export default ProductView;
